import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledTextCarousel = styled.div`
  display: flex;
  max-width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: 0;
  padding: 24px 0 50px 0;

  @media ${device.tablet} {
    flex-direction: row;
  }

  .t-left-arrow,
  .t-right-arrow {
    display: none;
    margin-top: 90px;
    width: 32px;
    height: 32px;
    border-top: 2px solid #c3cbcd;
    border-right: 2px solid #c3cbcd;
    cursor: pointer;

    @media ${device.tablet} {
      display: flex;
    }
  }

  .t-left-arrow {
    transform: rotate(225deg);
  }

  .t-right-arrow {
    transform: rotate(45deg);
  }

  .mobile-navigator {
    display: flex;
    justify-content: center;

    span {
      cursor: pointer;
      border: 1px solid #666666;
      background: #666666;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin: 24px 3px;

      &.selected {
        border: 1px solid ${(props) => props.theme.navigation.fontColor};
        background: ${(props) => props.theme.navigation.fontColor};
      }
    }

    @media ${device.tablet} {
      display: none;
    }
  }

  .t-carousel-content-wrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    height: 250px;
    min-height: 250px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;

    .t-carousel-content {
      display: flex;
      transition: all 1s ease-in-out;
      flex-direction: row;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.2px;
      position: absolute;
    }
  }
`;

export const StyledCarouselContent = styled.div`
   {
    display: flex;
    flex-direction: column;
    transition: opacity 1s ease-in-out;
    transition: margin-left 1s ease-in-out;
    margin-left: 0;
    width: calc(100vw - 40px);
    overflow: hidden;

    @media ${device.tablet} {
      display: flex;
      width: calc(calc(100vw - 48px) / 2);
      max-width: 525px;
      height: 240px;
      max-height: 240px;
      flex-direction: column;
      padding: 0 12px;
    }
  }
`;

export const StyledQuoteBlock = styled.div`
  display: flex;
  height: 50px;
  max-height: 50px;
  padding: 0 0 24px 0;
  min-width: 100%;
`;

export const StyledQuote = styled.img`
  display: flex;
  width: 30px;
  margin-right: 6px;
`;
