import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledHeader = styled.header`
  display: flex;  
  align-items: center;
  position: fixed;
  z-index: 1009;
  top: 0;  
  left: 0;
  right: 0;

  border-bottom: 1px solid ${(props) =>
    props.theme.name === 'ea' ? props.theme.outlineColor : 'transparent'};
  background-color: ${(props) => props.theme.header.backgroundColor};

  color: ${(props) => props.theme.header.fontColor};
  ${(props) =>
    props.theme.name === 'pl'
      ? `min-height: 65px; padding: 12px 20px 12px 17px;`
      : `min-height: 88px; padding: 0px 20px;`}

  @media ${device.plNavBreak} {
    ${(props) =>
      props.theme.name === 'pl' &&
      `min-height: 65px; padding: 38px 20px 0px 20px;`}
  }

  @media ${device.eaNavBreak} {
    ${(props) =>
      props.theme.name === 'ea' && `min-height: 88px; padding: 0px 20px;`}
  }
  
  .nav-wrapper {
    @media ${device.plNavBreak} {
      background-color: ${(props) => props.theme.navigation.backgroundColor};
      ${(props) =>
        props.theme.name === 'pl' &&
        `
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding: 0 20px;
      height: 38px;
    `}
    }

    display: flex;
    flex-grow: 1;
    ${(props) =>
      props.theme.name === 'pl'
        ? `justify-content: center;`
        : `justify-content: flex-end;`}

        nav {
          justify-content: flex-end;
        }
`;

export const StyledSvg = styled.div`
  display: inline-block;
  vertical-align: top;
  max-height: 60px;
  padding: 8px 0 5px 0;

  img {
    height: 48px;

    ${(props) =>
      props.theme.name === 'pl' &&
      `@media ${device.plNavBreak} {
        height: 52px;
      }`}
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  ${(props) =>
    props.theme.name === 'ea' ? `max-width: 1380px;` : `max-width: 1200px;`}

  ${(props) =>
    props.theme.name === 'pl' &&
    `@media ${device.plNavBreak} {
        justify-content: space-between;
      }`}
`;
