import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import moment from 'moment';
import { ThemeContext } from "styled-components";
import Header from "../../../../components/header";
import HeaderInfo from "../../../../components/header-info";
import BodyInfo from "../../../../components/body-info";
import { ContentItems } from "../../../../components/body-info/interface";
import { HeroContentProps } from "../../../interface";
import Hero from "../../../../components/hero";
import Stripe from "../../../../components/stripe";
import Center from "../../../../components/center";
import CenterSelector from "../../../../components/centre-selector";
import HorizontalFilterBar from "../../../../components/horizontal-filter-bar";
import Button from "../../../../components/button";
import DatePicker from "../../../../components/date-picker";
import Session from "../../../../components/session";
import TextCarousel from "../../../../components/text-carousel";
import Footer from "../../../../components/footer";
import { StyledFilterBox, Body, SelectorBody, Background } from "../../../styles";
import mockCentresData from "../../../../mock/centres.json";
import {
  Centre,
  Class,
  FilterItem,
  SelectedFilter,
  FilterSection,
} from "../../../interface";
import {
  getClassIntensityLevel,
  getClassDoNotForgetText,
  getClassKeyBenefits,
  getClassDescription,
} from "../../../../mock/local-class-property-data";
import contentCopy from "../../content-copy.json";

import { HomePageVerticalTimetableProps } from "./interface";

const HomePageVerticalTimetableVariant: React.FC<HomePageVerticalTimetableProps> = ({
  initialSelectedCentre,
  isShowMoreDropdownContentAvailable
}) => {
  const verticalTimetableRef = useRef<HTMLDivElement>(null);
  const availableCentres = mockCentresData;

  /*-- use state properties --*/
  const [isInitialCentreSelectionMade, setIsInitialCentreSelectionMade] = useState<boolean>(false);
  const [selectedCentre, setSelectedCentre] = useState<Centre | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedCentreClasses, setSelectedCentreClasses] = useState<Class[]>(
    []
  );
  const [selectedCentreFilters, setSelectedCentreFilters] = useState<
    FilterSection[]
  >([]);
  const [currentlySelectedFilters, setCurrentlySelectedFilters] = useState<
    SelectedFilter[]
  >([]);
  const [
    isLoadingSelectedCentreContent,
    setIsLoadingSelectedCentreContent,
  ] = useState<boolean>(true);

  /*-- use effect functions --*/
  useEffect(() => {
    scrollToVerticalTimetable();
    if (initialSelectedCentre !== null && initialSelectedCentre !== undefined) {
      setSelectedCentre(initialSelectedCentre);
    }
  }, []);

  useEffect(() => {
    if (
      selectedCentre !== null &&
      selectedCentre !== undefined &&
      selectedDate != null &&
      selectedDate !== undefined
    ) {
      getSelectedCentreClasses(selectedCentre, selectedDate);
    }
  }, [selectedCentre, selectedDate]);

  useEffect(() => {
    if (
      selectedCentreClasses !== null &&
      selectedCentreClasses !== undefined &&
      selectedCentreClasses.length > 0
    ) {
      getSelectedCentreClassFilters(selectedCentreClasses);
    }
  }, [selectedCentreClasses]);

  const getSelectedCentreClasses = (
    selectedCentre: Centre | undefined,
    date: Date
  ): void => {
    /*-- once the api is published we will use the selectedCentre and date params to get classes, but for now it is mock --*/
    if (selectedCentre !== undefined) {
      setIsInitialCentreSelectionMade(true);
      setIsLoadingSelectedCentreContent(
        true
      ); /*-- we will end this loading state after filter manipulation --*/
      const centreSiteId: string = selectedCentre.site_id;
      const centreSiteIdStrippedOfLeadingZeros: string = centreSiteId.replace('00', '');
      const formattedStartDateString: string = moment(date).format('YYYY-MM-DD');
      const endDateString: moment.Moment = moment(formattedStartDateString).add(1, 'days');
      const formattedEndDateString: string = moment(endDateString).format('YYYY-MM-DD');
  
      axios({
        url: `https://api.everyoneactive.com/v1.0/courses/${centreSiteIdStrippedOfLeadingZeros}?sportsengland=true&cache=true`,
        method: 'post',
        data: {
          from: formattedStartDateString,
          to: formattedEndDateString,
          activityId: ['GRPEX']
        }
      }).then((response) => {
        const selectedCentreClasses: Class[] = response.data.map((classData: Class) => {
          const classDataName: string = classData.Description;
          const modifiedClassData: Class = {
            ...classData,
            CourseDescription: getClassDescription(classDataName),
            Intensity: getClassIntensityLevel(classDataName),
            DoNotForgetDescription: getClassDoNotForgetText(classDataName),
            KeyBenefits: getClassKeyBenefits(classDataName),
            StartTime: "",
            EndTime: ""
          };
          return modifiedClassData;
        });
        setSelectedCentreClasses(selectedCentreClasses);
      }).catch((error) => {
        console.error(error);
      });
    }
  };

  const getSelectedCentreClassFilters = (
    selectedCentreClasses: Class[]
  ): void => {
    /*-- activity section --*/
    const selectedCentreClassesDisplayNames: string[] = selectedCentreClasses.map(
      (selectedCentreClass) => selectedCentreClass.Description
    );
    const selectedCentreClassesDisplayNamesWithoutDuplicates: string[] = selectedCentreClassesDisplayNames.filter(
      (selectedCentreClassesDisplayName, index, self) =>
        index === self.findIndex((i) => i.trim() === selectedCentreClassesDisplayName.trim())
    );
    const classNameSectionFilters: FilterItem[] = selectedCentreClassesDisplayNamesWithoutDuplicates.map(
      (selectedCentreClassesDisplayName) => {
        return {
          filterName: selectedCentreClassesDisplayName,
          isSelected: true,
        };
      }
    );
    const classNamesFilterSection: FilterSection = {
      sectionName: "Activity",
      sectionFilters: classNameSectionFilters,
    };

    /*-- group size section --*/
    const selectedCentreClassesTotalSpaces: number[] = selectedCentreClasses.map(
      (selectedCentreClass) => selectedCentreClass.MaxBookees
    );
    const selectedCentreClassesTotalSpacesWithoutDuplicates: number[] = selectedCentreClassesTotalSpaces.filter(
      (selectedCentreClassesTotalSpace, index, self) =>
        index === self.findIndex((i) => i === selectedCentreClassesTotalSpace)
    );
    const ascendingSelectedCentreClassTotalSpaces: number[] = selectedCentreClassesTotalSpacesWithoutDuplicates.sort(
      (number1, number2) => number1 - number2
    );
    const ascendingSelectedCentreClassTotalSpaceRangeDivisibles: number[] = ascendingSelectedCentreClassTotalSpaces.map(
      (ascendingSelectedCentreClassTotalSpace) =>
        Math.floor(ascendingSelectedCentreClassTotalSpace / 10.1)
    );
    const ascendingSelectedCentreClassTotalSpaceRangeDivisiblesWithoutDuplicates: number[] = ascendingSelectedCentreClassTotalSpaceRangeDivisibles.filter(
      (ascendingSelectedCentreClassTotalSpaceRangeDivisible, index, self) =>
        index ===
        self.findIndex(
          (i) => i === ascendingSelectedCentreClassTotalSpaceRangeDivisible
        )
    );
    const classSizeSectionFilters: FilterItem[] = ascendingSelectedCentreClassTotalSpaceRangeDivisiblesWithoutDuplicates.map(
      (ascendingSelectedCentreClassTotalSpaceRangeDivisible) => {
        return {
          filterName: `${
            ascendingSelectedCentreClassTotalSpaceRangeDivisible * 10 + 1
          }-${(ascendingSelectedCentreClassTotalSpaceRangeDivisible + 1) * 10}`,
          isSelected: true,
        };
      }
    );
    const classSizeFilterSection: FilterSection = {
      sectionName: "Group size",
      sectionFilters: classSizeSectionFilters,
    };

    /*-- intensity section --*/
    const selectedCentreClassesIntensityLevels: string[] = selectedCentreClasses.map(
      (selectedCentreClass) => selectedCentreClass.Intensity
    );
    const selectedCentreClassesIntensityLevelsWithoutDuplicates: string[] = selectedCentreClassesIntensityLevels.filter(
      (selectedCentreClassesIntensityLevel, index, self) =>
        index ===
        self.findIndex((i) => i === selectedCentreClassesIntensityLevel)
    );
    const classIntensitySectionFilters: FilterItem[] = selectedCentreClassesIntensityLevelsWithoutDuplicates.map(
      (selectedCentreIntensityFilter) => {
        return {
          filterName: selectedCentreIntensityFilter,
          isSelected: true,
        };
      }
    );
    const classIntensityFilterSection: FilterSection = {
      sectionName: "Intensity",
      sectionFilters: classIntensitySectionFilters,
    };

    /*-- consolodate them all together as one array of filter sections --*/
    const selectedCentreFilters: FilterSection[] = [
      classNamesFilterSection,
      classSizeFilterSection,
      classIntensityFilterSection,
    ];

    /*-- get the currently selected filters (at this point it will be all of them) --*/
    const selectedFilters: SelectedFilter[] = selectedCentreFilters
      .map((selectedCentreFilterSection) =>
        selectedCentreFilterSection.sectionFilters.map((sectionFilter) => {
          return {
            filterType: selectedCentreFilterSection.sectionName,
            filterName: sectionFilter.filterName,
          };
        })
      )
      .flat();

    /*-- set the state --*/
    setSelectedCentreFilters(selectedCentreFilters);
    setCurrentlySelectedFilters(selectedFilters);
    setIsLoadingSelectedCentreContent(false);
  };

  const resetFilters = () => {
    getSelectedCentreClassFilters(selectedCentreClasses);
  };

  const getFilterSection = (
    _currentlySelectedFilters: SelectedFilter[],
    requiredFilterSectionType: string
  ): SelectedFilter[] => {
    return _currentlySelectedFilters.filter(
      (currentlySelectedFilter) =>
        currentlySelectedFilter.filterType === requiredFilterSectionType
    );
  };

  const getFilteredClassesData = (
    selectedCentreClasses: Class[],
    currentlySelectedFilters: SelectedFilter[]
  ): Class[] => {
    return selectedCentreClasses.reduce((classes: Class[], currentClass) => {
      const selectedCentreClassName: string = currentClass.Description;
      const selectedCentreClassTotalSpaces: number = currentClass.MaxBookees;
      const selectedCentreClassIntensityLevel: string = currentClass.Intensity;

      const selectedFiltersIncludesSelectedCentreClassName: boolean =
        getFilterSection(currentlySelectedFilters, "Activity").filter(
          (activity) => activity.filterName === selectedCentreClassName
        ).length > 0;
      const selectedFiltersIncludesSelectedCentreClassTotalSpaces: boolean =
        getFilterSection(currentlySelectedFilters, "Group size").filter(
          (groupSizeFilter) => {
            const range: number[] = groupSizeFilter.filterName
              .split("-")
              .map((rangeValue) => parseInt(rangeValue));
            return (
              (selectedCentreClassTotalSpaces - range[0]) *
                (selectedCentreClassTotalSpaces - range[1]) <=
              0
            );
          }
        ).length > 0;
      const selectedFiltersIncludesSelectedCentreClassIntensityLevel: boolean =
        getFilterSection(currentlySelectedFilters, "Intensity").filter(
          (intensityFilter) => {
            return (
              intensityFilter.filterName === selectedCentreClassIntensityLevel
            );
          }
        ).length > 0;

      if (
        selectedFiltersIncludesSelectedCentreClassName &&
        selectedFiltersIncludesSelectedCentreClassTotalSpaces &&
        selectedFiltersIncludesSelectedCentreClassIntensityLevel
      ) {
        classes.push(currentClass);
      }
      return classes;
    }, []);
  };

  const filtersDidChange = (
    filterSectionName: string,
    filterItemName: string
  ): void => {
    let updatedSelectedCentreClassFilters: FilterSection[] = [
      ...selectedCentreFilters,
    ];
    let updatedCurrentlySelectedFilters: SelectedFilter[] = [
      ...currentlySelectedFilters,
    ];

    if (
      updatedSelectedCentreClassFilters.findIndex(
        (filterSection) => filterSection.sectionName === filterSectionName
      ) > -1
    ) {
      let matchingFilterSectionIndex: number = updatedSelectedCentreClassFilters.findIndex(
        (filterSection) => filterSection.sectionName === filterSectionName
      )!;
      let matchingFilterSection: FilterSection =
        updatedSelectedCentreClassFilters[matchingFilterSectionIndex];
      if (
        matchingFilterSection.sectionFilters.findIndex(
          (sectionFilter) => sectionFilter.filterName === filterItemName
        ) > -1
      ) {
        let matchingFilterSectionSectionFilterIndex: number = matchingFilterSection.sectionFilters.findIndex(
          (sectionFilter) => sectionFilter.filterName === filterItemName
        )!;
        let matchingFilterSectionSectionFilter: FilterItem =
          matchingFilterSection.sectionFilters[
            matchingFilterSectionSectionFilterIndex
          ];
        matchingFilterSectionSectionFilter.isSelected = !matchingFilterSectionSectionFilter.isSelected;
        if (matchingFilterSectionSectionFilter.isSelected) {
          const selectedFilterToAdd: SelectedFilter = {
            filterType: filterSectionName,
            filterName: filterItemName,
          };
          updatedCurrentlySelectedFilters.push(selectedFilterToAdd);
        } else {
          updatedCurrentlySelectedFilters = updatedCurrentlySelectedFilters.filter(
            (selectedFilter) => selectedFilter.filterName !== filterItemName
          );
        }
        setSelectedCentreFilters(updatedSelectedCentreClassFilters);
        setCurrentlySelectedFilters(updatedCurrentlySelectedFilters);
      }
    }
  };

  const scrollToVerticalTimetable = (): void => {
    verticalTimetableRef?.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
  }

  const {
    heroImage,
    contentItems,
  }: {
    heroImage: string;
    carouselItems: Array<string>;
    contentItems: ContentItems;
    heroContent: HeroContentProps;
  } = useContext(ThemeContext);

  return (
    <>
      <Header onLogin={() => {}} />
      <HeaderInfo />
      <Hero
        backgroundImage={heroImage}
        title={contentCopy.hero.title}
        description={contentCopy.hero.description}
        requiredButtonType={"button"}
        buttonId={contentCopy.hero.buttonId}
        buttonText={contentCopy.hero.buttonText}
        onClick={() => scrollToVerticalTimetable()}
      ></Hero>
      <Body>
        <Center>
          <h2 style={{ margin: "40px 0 24px 0" }} className="ea">
            {contentCopy.contentItems.title}
          </h2>
          <p style={{ marginBottom: "22px" }} className="ea">
            {contentCopy.contentItems.description}
          </p>
        </Center>
        <BodyInfo faqs={contentCopy.contentItems.faqs} />
        <Center>
          <span>
            <Button
              size="large"
              label="View Classes"
              onClick={() => scrollToVerticalTimetable()}
            />
          </span>
        </Center>
        <Center>
          <TextCarousel items={contentCopy.carouselItems} />
        </Center>
      </Body>
      <Stripe>
        <b>{contentItems.footer.bold}</b>
        <span>{contentItems.footer.normal}</span>
      </Stripe>
      <Background>
        <Body>
          <SelectorBody>
            <div ref={verticalTimetableRef} className="selector-body-wrapper">
                <Center>
                {(initialSelectedCentre === null || initialSelectedCentre === undefined) &&
                  <CenterSelector
                    currentlySelectedCentre={selectedCentre}
                    availableCentres={availableCentres}
                    onViewClassesButtonClick={(newlySelectedCentre) => {
                      setSelectedCentre(newlySelectedCentre);
                      getSelectedCentreClasses(selectedCentre, selectedDate);
                    }}
                  />
                }
                </Center>
              {isInitialCentreSelectionMade ? 
                <>
                  <HorizontalFilterBar
                    isLoadingSelectedCentreContent={isLoadingSelectedCentreContent} 
                    selectedCentreFilters={selectedCentreFilters}
                    filterItemWasPressed={(filterSectionName, filterItemName) => filtersDidChange(filterSectionName, filterItemName)}
                  />
                  {isLoadingSelectedCentreContent ? (
                    <StyledFilterBox>
                      <div className="filtered-data-wrapper">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "240px",
                            height: "56px",
                            borderRadius: "6px",
                            backgroundColor: "#fff",
                            padding: "0 12px",
                          }}
                        >
                          <div
                            style={{
                              width: "111px",
                              height: "32px",
                              borderRadius: "4px",
                              backgroundColor: "#f6f9fa",
                            }}
                          ></div>
                          <div
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "4px",
                              backgroundColor: "#f6f9fa",
                            }}
                          ></div>
                        </div>
                        {[...Array(5)].map((_, i) => (
                          <div
                            key={i}
                            style={{
                              borderRadius: "12px",
                              backgroundColor: "#fff",
                              padding: "12px",
                              marginTop: "12px",
                            }}
                          >
                            <div
                              style={{
                                width: "90px",
                                height: "18px",
                                borderRadius: "3px",
                                backgroundColor: "#f6f9fa",
                              }}
                            ></div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                margin: "12px 0",
                              }}
                            >
                              <div
                                style={{
                                  width: "124px",
                                  height: "36px",
                                  borderRadius: "4px",
                                  backgroundColor: "#f6f9fa",
                                }}
                              ></div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "3px",
                                    backgroundColor: "#f6f9fa",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: "90px",
                                    height: "24px",
                                    borderRadius: "3px",
                                    backgroundColor: "#f6f9fa",
                                    marginLeft: "4px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: "80px",
                                    height: "32px",
                                    borderRadius: "16px",
                                    backgroundColor: "#f6f9fa",
                                    marginLeft: "36px",
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "90px",
                                height: "18px",
                                borderRadius: "3px",
                                backgroundColor: "#f6f9fa",
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </StyledFilterBox>
                  ) : selectedCentreClasses.length > 0 ? (
                    <StyledFilterBox>
                      <div className="filtered-data-wrapper">
                        <DatePicker
                          currentlySelectedDate={selectedDate}
                          onDateChange={(newlySelectedDate) => {
                            setSelectedDate(newlySelectedDate);
                            getSelectedCentreClasses(selectedCentre, selectedDate);
                          }}
                        />
                        {getFilteredClassesData(
                          selectedCentreClasses,
                          currentlySelectedFilters
                        ).length > 0 ? (
                          getFilteredClassesData(
                            selectedCentreClasses,
                            currentlySelectedFilters
                          ).map((session: Class) => (
                            <Session key={session.CourseId} session={session} isShowMoreDropdownContentAvailable={isShowMoreDropdownContentAvailable}/>
                          ))
                        ) : (
                          <div className="filter-no-data">
                            <h4>No classes match your filters</h4>
                            <p>
                              There are currently no available classes at this
                              location on this date which match your filtering
                              criteria. Please update your filters, or change the
                              location or date to find alternative classes that are
                              available.
                            </p>
                          </div>
                        )}
                      </div>
                    </StyledFilterBox>
                  ) : (
                    <div className="filter-no-data">
                      <h4>No classes found</h4>
                      <p>
                        There are no available classes at this location on this
                        date. Please change the location or date to find alternative
                        classes that are available.
                      </p>
                    </div>
                  )}
                </> : 
                <>{/*-- Nothing needed here --*/}</>
              }
            </div>
          </SelectorBody>
        </Body>
      </Background>
      <Footer />
    </>
  );
};

HomePageVerticalTimetableVariant.defaultProps = {
  initialSelectedCentre: undefined,
  isShowMoreDropdownContentAvailable: false
}

export default HomePageVerticalTimetableVariant;
