import styled from "styled-components";

export const StyledInfoIcon = styled.div`
  display: inline-block;
  position: relative;
  width: 39px;
  height: 39px;
  border: 2px solid ${(props) => props.theme.button.secondaryBackgroundColor};
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 50%;

  .ellipse-3 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: 4px;
    top: 4px;
    background: ${(props) => props.theme.button.secondaryBackgroundColor};
    border-radius: 50%;

    .icon-i {
      position: absolute;
      left: 11px;
      top: 2px;
      font-family: Times;
      font-style: italic;
      font-weight: bold;
      font-size: 21px;
      line-height: 24px;
      color: #ffffff;
    }
  }
`;
