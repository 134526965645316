import React from "react";
import { StyledIntensity } from "./styles";
import { IntensityProps } from "./interface";

const Intensity: React.FC<IntensityProps> = ({ intensityLevel }) => {
  return (
    <StyledIntensity>
      <span className={"active"}></span>
      <span className={intensityLevel === "Medium" || intensityLevel === "High" ? "active" : ""}></span>
      <span className={intensityLevel === "Medium" || intensityLevel === "High" ? "active" : ""}></span>
      <span className={intensityLevel === "High" ? "active" : ""}></span>
      <span className={intensityLevel === "High" ? "active" : ""}></span>
    </StyledIntensity>
  );
};

Intensity.defaultProps = {
  intensityLevel: "Medium",
};
export default Intensity;
