import React from "react";
import { StyledInfoIcon } from "./styles";
import { InfoIconProps } from "./interface";

const InfoIcon: React.FC<InfoIconProps> = () => {
  return (
    <StyledInfoIcon>
      <div className="ellipse-3">
        <div className="icon-i">i</div>
      </div>
    </StyledInfoIcon>
  );
};

export default InfoIcon;
