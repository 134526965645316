import React, { useState } from "react";
import { StyledNavigation, StyledMobileToggle } from "./styles";
import { NavigationProps, NavigationItems } from "./interface";

const Navigation: React.FC<NavigationProps> = ({ items }) => {
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
  const classOpen = mobileMenuToggle ? "open" : "";

  return (
    <StyledNavigation>
      <ul className={classOpen}>
        {items.map((item: NavigationItems, idx: number) => (
          <li key={idx}>
            <a href={item.href}>{item.title}</a>
          </li>
        ))}
      </ul>
      <StyledMobileToggle
        className={classOpen}
        onClick={() => setMobileMenuToggle(!mobileMenuToggle)}
      >
        <span>Menu</span>
        <div className={classOpen}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </StyledMobileToggle>
    </StyledNavigation>
  );
};

export default Navigation;
