import styled from 'styled-components';

export const StyledInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.infoBox.backgroundColor};
  color: ${(props) => props.theme.infoBox.fontColor};
  padding 10px 0;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    ${(props) =>
      props.theme.name === 'ea'
        ? `padding: 0.5rem 0 0.5rem 0.5rem;`
        : 'padding: 0.5rem;'}

    img {
      max-height: 40px;
    }
  }
`;
