import React from "react";
import { StyledBodyInfoBox } from "./styles";
import { BodyInfoProps, ContentItems } from "./interface";
import BodyInfoBox from "../body-info-box";

const BodyInfo: React.FC<BodyInfoProps> = ({ faqs }) => {

  return (
    <StyledBodyInfoBox>
      {faqs.map((faq, idx) => (
        <BodyInfoBox key={idx} faq={faq}/>
      ))}
    </StyledBodyInfoBox>
  );
};

export default BodyInfo;
