import styled from 'styled-components';
import { device } from '../themes/breakpoints';

export const StyledFilterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 -20px;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  }

  .filter-wrapper {
    .filter-bg {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 0 15px;
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      background-color: ${(props) => props.theme.primaryBackgroundColor};
      margin-bottom: 12px;
    }

    @media ${device.tablet} {
      margin-bottom: 0;
      margin-right: 12px;
    }

    .filter-head {
      display: flex;
      justify-content: space-between;

      span {
        color: ${(props) => props.theme.highlightColor};
        text-decoration: underline;
        cursor: pointer;
        padding: 0;
        margin: 24px 0;
        font-size: 14px;
      }
    }

    .filter-head--loading {
      * {
        margin: 24px 0;
        background-color: ${(props) => props.theme.contentBackgroundColor};
        height: 24px;
      }

      div:first-of-type {
        width: 111px;
        margin-right: 12px;
      }

      div:last-of-type {
        width: 30px;
        margin-left: 12px;
      }
    }
  }

  .filtered-data-wrapper {
    flex-grow: 1;
  }

  .filter-no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 16px;

    * {
      text-align: center;
      max-width: 600px;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${(props) => props.theme.contentBackgroundColor};

  @media ${device.tablet} {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
`;

export const Background = styled.div`
  background-color: ${(props) => props.theme.contentBackgroundColor};
}
`;

export const SelectorBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  div {
    &.selector-body-wrapper {
      flex-direction: row;
      width: calc(100% - 24px);
      margin: 0 12px;

      @media ${device.tablet} {
        width: 100%;
        margin: 0 0;
        max-width: 1200px;
      }
    }
  }
`;
