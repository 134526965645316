import React from 'react';
import ButtonLink from '../button-link';
import Button from "../button";
import { StyledHero, StyledHeroWrapper } from './styles';
import { HeroProps } from './interface';

const Hero: React.FC<HeroProps> = ({ 
  backgroundImage, 
  title, 
  description, 
  requiredButtonType = "link", 
  buttonId, 
  buttonText, 
  onClick = () => {}
}) => {
  const style = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <StyledHero style={style}>
      <StyledHeroWrapper>
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
          <div>
            {requiredButtonType === "button" && <Button size="medium" label={buttonText} onClick={() => onClick()}/>}
            {requiredButtonType === "link" && <ButtonLink size="medium" id={buttonId} label={buttonText} href="https://profile.everyoneactive.com/login" />}
          </div>
        </div>
      </StyledHeroWrapper>
    </StyledHero>
  );
};

export default Hero;
