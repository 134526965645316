import React from "react";
import HomePage from "./pages/home";

interface ThemeProps {
  children: string | React.ReactNode;
}

const themeValid = ["EA", "PL"].find(
  (themeName) => process.env.REACT_APP_THEME === themeName
);
const EaTheme = React.lazy(() => import("./themes/ea/provider"));
const PlTheme = React.lazy(() => import("./themes/pl/provider"));

const ThemeSelector: React.FC<ThemeProps> = ({ children }) => {
  return (
    <>
      <React.Suspense fallback={<></>}>
        {process.env.REACT_APP_THEME === "EA" && (
          <EaTheme children={children} />
        )}
        {process.env.REACT_APP_THEME === "PL" && (
          <PlTheme children={children} />
        )}
      </React.Suspense>
      {themeValid ? <></> : <div>Error: Invalid theme selected</div>}
    </>
  );
};

function App() {
  return (
    <ThemeSelector>
      <HomePage />
    </ThemeSelector>
  );
}

export default App;
