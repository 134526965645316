import React from "react";
import { StyledWarningIcon } from "./styles";
import { WarningIconProps } from "./interface";

const WarningIcon: React.FC<WarningIconProps> = () => {
  return (
    <StyledWarningIcon>
      <span>!</span>
    </StyledWarningIcon>
  );
};

export default WarningIcon;
