import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledHero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 20px 60px;
  min-height: 400px;
  background-position: 30% 50%;
  background-size: cover;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;

  ${(props) =>
    props.theme.name === 'pl' &&
    `@media ${device.plNavBreak} {
      font-size: 22px;
      line-height: 35px;
      min-height: 495px;
      padding: 50px 20px 60px;
    }`}

  ${(props) =>
    props.theme.name === 'ea' &&
    `@media ${device.eaNavBreak} {
      font-size: 22px;
      line-height: 35px;
      min-height: 495px;
      padding: 50px 20px 60px;
    }`}

    h1 {
      @media ${device.maxMobileL} {
        font-size: 36px;
      }
    }

    p {
      color: #fff;
    }

  }
`;

export const StyledHeroWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;

  div {
    max-width: 620px;
  }
`;
