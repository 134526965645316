import styled from "styled-components";

export const StyledIntensityFilter = styled.div`
  position: relative;

  .filter-label {
    color: #333333;
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    padding: 0 0 8px !important;
  }

  .filter-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border: 2px solid lightgrey;
    height: 40px;
    padding: 0 10px;
    cursor: pointer;

    span {
      flex: 1;
      padding: 12px 0;
      color: #333333;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .filter-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;
    background-color: white;
    z-index: 1;
    margin: -2px;
    padding: 0 0 12px;
    left: 2px;
    right: 2px;
    border-left: 2px solid lightgrey;
    border-right: 2px solid lightgray;
    border-bottom: 2px solid lightgray;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 6px 12px;
      cursor: pointer;

      p {
        margin: 0;
        padding: 0;
        color: #333333;
        font-size: 1rem;
        font-weight: 400;
      }

      &:hover {
        background-color: #fbfbfb;
      }
    }
  }
`;
