import React from "react";
import InfoIcon from "../info-icon";
import { StyledBodyInfoBox } from "./styles";
import { BodyInfoBoxProps } from "./interface";

const BodyInfoBox: React.FC<BodyInfoBoxProps> = ({ faq }) => {
  return (
    <StyledBodyInfoBox>
      <div>
        <InfoIcon />
      </div>
      <h3>{faq.question}</h3>
      <div>{faq.answer}</div>
    </StyledBodyInfoBox>
  );
};

export default BodyInfoBox;
