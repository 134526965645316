import React from "react";
import { StyledCheckIcon } from "./styles";
import { CheckIconProps } from "./interface";

const CheckIcon: React.FC<CheckIconProps> = () => {
  return (
    <StyledCheckIcon>
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.0361 0.515917C12.7316 0.221827 12.2489 0.221827 11.9445 0.515917L4.64282 7.81753L2.05544 5.23015C1.75398 4.91802 1.25654 4.90941 0.944451 5.21086C0.632314 5.51232 0.623705 6.00976 0.925161 6.32185C0.931469 6.32839 0.937914 6.33483 0.944451 6.34114L4.08732 9.48401C4.39412 9.79076 4.89151 9.79076 5.19831 9.48401L13.0555 1.62686C13.3569 1.31477 13.3483 0.817373 13.0361 0.515917Z"
          fill="white"
        />
      </svg>
    </StyledCheckIcon>
  );
};

export default CheckIcon;
