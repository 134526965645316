import React from 'react';
import CheckIcon from '../check-icon';
import WarningIcon from '../warning-icon';
import { StyledSessionToggle } from './styles';
import { SessionToggleProps } from './interface';
import pinIcon from '../../resources/icons/pin.svg';
import instructorIcon from '../../resources/icons/instructor.svg';
import Intensity from "../../components/intensity";
import { Class } from "../../pages/interface";

const SessionToggle: React.FC<SessionToggleProps> = ({ session }) => {

  const getSessionAvailableSpaces = (session: Class): number => {
    return session.Sessions[0].AvailableSpaces;
  }

  return (
    <StyledSessionToggle>
      <div className="session-toggle">
        <div className="session-toggle__top session-top">
          <div className="session-top__left">
            <div>
              <h4>Activity Information</h4>
              {session.CourseDescription &&
                <p>{session.CourseDescription}</p>
              }
            </div>
            <div className="toggle-detail">
              <span className="toggle-detail__item">
                <Intensity intensityLevel={session.Intensity} />
                <p>{session.Intensity} Intensity</p>
              </span>
              <span className="toggle-detail__item">
                <img src={instructorIcon} alt="Instructor" />
                <p>Spaces remaining{' '}
                {getSessionAvailableSpaces(session)} of {session.MaxBookees}</p>
              </span>
            </div>
          </div>
          <div className="session-top__right">
            <div>
              <h4>Benefits</h4>
              <ul>
                {session.KeyBenefits.map((keyBenefit, i) => (
                  <li key={i}>
                    <CheckIcon/>
                    {keyBenefit}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="session-toggle__bottom">
          <div className="highlight">
            <div>
              <WarningIcon />
              <h5>Don't forget...</h5>
            </div>
            <p>
              {session.DoNotForgetDescription}
            </p>
          </div>
        </div>
      </div>
    </StyledSessionToggle>
  );
};

export default SessionToggle;
