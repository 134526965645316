import React from "react";
import { LoadingSpinner, StyledHorizontalFilterBar } from "./styles";
import { HorizontalFilterBarProps } from "./interface";
import ActivityNameFilter from "./activity-name-filter";
import ClassSizeFilter from "./class-size-filter";
import IntensityFilter from "./intensity-filter";
import { FilterSection } from "../../pages/interface";

const HorizontalFilterBar: React.FC<HorizontalFilterBarProps> = ({
  isLoadingSelectedCentreContent,
  selectedCentreFilters,
  filterItemWasPressed,
}) => {
  const getFilterSection = (requiredSectionName: string): FilterSection => {
    return selectedCentreFilters.filter(
      (filterSection: FilterSection) =>
        filterSection.sectionName === requiredSectionName
    )[0];
  };

  return isLoadingSelectedCentreContent ? (
    <StyledHorizontalFilterBar>
      <LoadingSpinner />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "96px",
            height: "18px",
            marginBottom: "12px",
            borderRadius: "4px",
            backgroundColor: "#fff",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: "40px",
            borderRadius: "4px",
            backgroundColor: "#fff",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "96px",
            height: "18px",
            marginBottom: "12px",
            borderRadius: "4px",
            backgroundColor: "#fff",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: "40px",
            borderRadius: "4px",
            backgroundColor: "#fff",
          }}
        ></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "96px",
            height: "18px",
            marginBottom: "12px",
            borderRadius: "4px",
            backgroundColor: "#fff",
          }}
        ></div>
        <div
          style={{
            width: "100%",
            height: "40px",
            borderRadius: "4px",
            backgroundColor: "#fff",
          }}
        ></div>
      </div>
    </StyledHorizontalFilterBar>
  ) : (
    <StyledHorizontalFilterBar>
      <ActivityNameFilter
        activityNameFilterSection={getFilterSection("Activity")}
        activityFilterWasPressed={(activityName) =>
          filterItemWasPressed("Activity", activityName)
        }
      />
      {/* <ClassSizeFilter classSizeFilterSection={getFilterSection("Group size")} classSizeFilterWasPressed={(classSizeName) => filterItemWasPressed("Group size", classSizeName)}/> */}
      <IntensityFilter
        intensityFilterSection={getFilterSection("Intensity")}
        intensityFilterWasPressed={(intensityName) =>
          filterItemWasPressed("Intensity", intensityName)
        }
      />
    </StyledHorizontalFilterBar>
  );
};

export default HorizontalFilterBar;
