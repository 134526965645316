import React from "react";
import ReactDatePicker from "react-datepicker";
import { StyledDatePicker } from "./styles";
import { DatePickerProps } from "./interface";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker: React.FC<DatePickerProps> = ({
  currentlySelectedDate,
  onDateChange
}) => {
  return (
    <StyledDatePicker>
      <ReactDatePicker
        selected={currentlySelectedDate}
        onChange={(date: Date) => {
          if (date !== undefined && date !== currentlySelectedDate) {
            onDateChange(date);
          }
        }}
      />
    </StyledDatePicker>
  );
};

export default DatePicker;
