import React, { useState, useRef, useEffect } from "react";
import {
  StyledTextCarousel,
  StyledCarouselContent,
  StyledQuoteBlock,
  StyledQuote,
} from "./styles";
import { TextCarouselProps } from "./interface";
import quoteImage from "../../resources/icons/quote.svg";

const QuoteImage = () => <StyledQuote src={quoteImage} alt="" />;

const TextCarousel: React.FC<TextCarouselProps> = ({ items }) => {
  const sliderBox = useRef<HTMLDivElement>(null);
  const [leftPos, setLeftPos] = useState(0);
  const [slideBoxWidth, setSlideBoxWidth] = useState(0);

  useEffect(() => {
    if (
      sliderBox.current &&
      sliderBox.current.getBoundingClientRect().width !== slideBoxWidth
    ) {
      setSlideBoxWidth(sliderBox.current.getBoundingClientRect().width);
    }
  }, [slideBoxWidth, setSlideBoxWidth]);

  if (
    sliderBox.current &&
    sliderBox.current.getBoundingClientRect().width !== slideBoxWidth
  ) {
    setSlideBoxWidth(sliderBox.current.getBoundingClientRect().width);
  }

  const maxLength = (items.length - 1) * slideBoxWidth;

  const leftClick = () => {
    setLeftPos(leftPos < 0 ? leftPos + slideBoxWidth : 0);
  };

  const rightClick = () => {
    setLeftPos(leftPos > -maxLength ? leftPos - slideBoxWidth : 0);
  };

  const selectItem = (index: number) => {
    setLeftPos(-(index * slideBoxWidth));
  };

  return (
    <>
      <StyledTextCarousel>
        {/* <span className="t-left-arrow" onClick={() => leftClick()}></span> */}
        <div className="t-carousel-content-wrapper">
          <div className="t-carousel-content" style={{ left: `${leftPos}px` }}>
            {items.map((text: string, idx: number) => (
              <StyledCarouselContent ref={sliderBox} key={idx}>
                <StyledQuoteBlock>
                  <QuoteImage />
                  <QuoteImage />
                </StyledQuoteBlock>
                <span>{text}</span>
              </StyledCarouselContent>
            ))}
          </div>
        </div>
        {/* <span className="t-right-arrow" onClick={() => rightClick()}></span> */}

        <div className="mobile-navigator">
          {Array.from(Array(items.length).keys()).map((idx: number) => (
            <span key={idx} onClick={() => selectItem(idx)}></span>
          ))}
        </div>
      </StyledTextCarousel>
    </>
  );
};

TextCarousel.defaultProps = {
  items: [],
};

export default TextCarousel;
