import styled from "styled-components";
import { device } from "../../themes/breakpoints";

export const StyledStripe = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  padding: 0px 12px;
  line-height: 30px;
  min-height: 140px;

  background-color: ${(props) => props.theme.stripe.backgroundColor};
  color: ${(props) => props.theme.stripe.fontColor};

  @media ${device.tablet} {
    line-height: 70px;
    min-height: 70px;
    flex-direction: row;

    span {
      text-indent: 6px;
    }
  }
`;
