import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledButton = styled.button`
  ${(props) =>
    props.theme.name === 'ea'
      ? `font-family: 'Barlow Semi Condensed', sans-serif;`
      : `font-family: 'PF DinText Pro', sans-serif;`}
  ${(props) =>
    props.theme.name === 'ea' ? `font-weight: 800;` : `font-weight: 400;`}
  border: 0;
  ${(props) => props.theme.name === 'ea' && `text-transform: uppercase;`}
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  ${(props) =>
    props.theme.name === 'ea'
      ? `text-transform: uppercase;`
      : `border-radius: 25px;`}
  
  &.button--primary {
    color: ${(props) => props.theme.button.primaryFontColor};
    background-color: ${(props) => props.theme.button.primaryBackgroundColor};
    border: 1px solid ${(props) => props.theme.button.primaryFontColor};
  }

  &.button--secondary {
    transition: all 0.4s ease-in-out;
    border: 1px solid ${(props) => props.theme.button.secondaryBackgroundColor};
    color: ${(props) => props.theme.button.secondaryFontColor};
    background-color: ${(props) => props.theme.button.secondaryBackgroundColor};
    &:hover {
      background-color: ${(props) => props.theme.button.secondaryFontColor};
      color: ${(props) => props.theme.button.secondaryBackgroundColor};
      border: 1px solid ${(props) =>
        props.theme.button.secondaryBackgroundColor};
    }
  }

  &.button--small {
    font-size: 12px;
    padding: 10px 16px;
  }

  &.button--medium {
    font-size: 16px;
    ${(props) =>
      props.theme.name === 'ea'
        ? `padding: 12px 32px;`
        : `padding: 14px 18px 10px 18px;`}
  }

  &.button--large {
    font-size: 18px;
    ${(props) =>
      props.theme.name === 'ea'
        ? `padding: 12px 18px;`
        : `padding: 18px 34px 14px 34px;`}
  }
}`;
