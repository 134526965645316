import React from "react";
import { StyledButton } from "./styles";
import { ButtonProps } from "./interface";

const Button: React.FC<ButtonProps> = ({
  onClick,
  primary = false,
  size = "medium",
  backgroundColor,
  label,
}) => {
  const mode = primary ? "button--primary" : "button--secondary";

  return (
    <StyledButton
      type="button"
      className={["button", `button--${size}`, mode].join(" ")}
      style={{ backgroundColor }}
      onClick={onClick}
    >
      {label}
    </StyledButton>
  );
};

export default Button;
