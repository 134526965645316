import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledBodyInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 24px 12px 0 12px;

  @media ${device.tablet} {
    width: 240px;
  }

  h3 {
    font-weight: bold;
    margin: 18px 0 10px 0;
  }

  div {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
`;
