import styled from 'styled-components';

export const StyledCheckIcon = styled.div`
  display: inline-flex;
  margin-right: 6px;
  position: relative;
  max-width: 20px;
  width: 19px;
  height: 19px;
  border: 1px solid #32cd32;
  background-color: #32cd32;
  border-radius: 50%;

  svg {
    display: block;
    width: 16px;
    position: absolute;
    top: 4px;
    left: 2px;
  }
`;
