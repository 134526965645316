import styled from 'styled-components';

export const StyledWarningIcon = styled.div`
  display: flex;
  position: relative;
  margin-right: 6px;
  position: relative;
  max-width: 20px;
  width: 16px;
  height: 16px;
  border: 1px solid red;
  background-color: ${(props) => props.theme.button.secondaryBackgroundColor};
  border-radius: 50%;
  padding: 0;

  span {
    position: absolute;
    color: white;
    display: block;
    width: 16px;
    top: -1px;
    left: 6px;
    font-size: 14px;
    font-weight: bold;
  }
`;
