import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledFooter = styled.footer`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  font-family: "Circular Std Book", sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  background-color: ${(props) => props.theme.footer.backgroundColor};

  h2 {
    display: flex;
    box-sizing: border-box;
    font-family: "Circular Std Book", sans-serif;
    color: ${(props) => props.theme.defaultFontColor};
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    border-bottom: 1px solid
      ${(props) => props.theme.footer.titleUnderlineColor};
  }

  .sub-footer {
    display: flex;
    box-sizing: border-box;
    font-size: 15px;
    justify-content: center;
    padding-top: 25px;
    
    ${(props) =>
      props.theme.name === 'ea' &&
      `
      padding: 12px 0;
      border-top: 1px solid white;
    `}
    

    div {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      max-width: 1200px;
      margin: 0;
      flex-direction: column-reverse;
      color: ${(props) => props.theme.footer.fontColor};
      justify-content: center;
      ${(props) => props.theme.name === 'ea' && `max-width: 900px;`}

      span {
        box-sizing: border-box;
        justify-content: flex-end;
        
        ${(props) =>
          props.theme.name === 'ea'
            ? `
          text-align: center;
          margin: 24px auto;
          flex-direction: row;
        `
            : `
          margin: 0 0 2px 0;
        `}

        span {
          &.copy {
            flex-direction: column;
            justify-content: flex-end;
          }
        }

        img {
          height: 30px;
          margin-top: 30px;
       }
      }

      @media ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  @media ${device.maxTablet} {
    h2 {
      position: relative;
      cursor: pointer;

      &::after {
        z-index: 1003;
        display: block;
        position: absolute;
        content: " ";
        width: 12px;
        height: 12px;
        border-right: 2px solid ${(props) => props.theme.footer.fontColor};
        border-top: 2px solid ${(props) => props.theme.footer.fontColor};
        right: 22px;
        top: 19px;
        transform: rotate(135deg);
        transition: all 1s ease-in-out;
      }

      &.open {
        &::after {
          transform: rotate(-50deg);
        }
      }
    }

    .toggle {
      overflow: hidden;
      transition: all 1s ease-in-out;
      max-height: 500px;

      &.hide {
        max-height: 0;
      }
    }
  }

  div {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    ${(props) =>
      props.theme.name === 'ea'
        ? `justify-content: center;`
        : `justify-content: space-evenly;`}

    @media ${device.tablet} {
      flex-direction: row;
    }
    
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      

      &.ul-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1200px;
        width: 100%;
        justify-content: flex-start;
      }

      div {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        justify-content: space-between;

        @media ${device.tablet} {
          flex-direction: row;
        }

        div,
        ul {
          display: flex;
          box-sizing: border-box;
          flex-direction: column;
          background: 0;
          margin: 0;
          padding: 0 24px 0 0;
        }

        div {
          justify-content: flex-start;
          padding: 0;

          a {
            display: flex;

            img {
              width: 110px;
            }
          }
        }
      }
    }

    ul {
      list-style: none;
      min-width: 200px;
      margin: 0;
      padding: 0;
      min-width: 100%;
 
      @media ${device.tablet} {
        min-width: auto;
      }
      
      &:first-child {
        margin-right: 0;
        @media ${device.tablet} {
          margin-right: 14vw;
        }
      }
      &:nth-child(2) {
        margin-right: 0;
        @media ${device.tablet} {
          margin-right: 12vw;
        }
      }   
      }

      li {
        padding: 3px 0;
        margin: 2px 0;
        color: ${(props) => props.theme.footer.fontColor};

        a {
          margin: 0;
          color: ${(props) => props.theme.footer.fontColor};
          ${(props) => props.theme.name === 'ea' && `text-decoration: none;`}
        }
      }
    }
  }

  .social-icons {
    display: none;
    ${(props) => (props.theme.name === 'ea' ? `width: 110px !important;` : ``)}
    
    @media ${device.tablet} {
      min-width: 200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between !important;
    }
    
    
    a {
      display: flex;
      justify-content: center;
      border-radius: 50%;
      margin-left: 20px;
  
      ${(props) =>
        props.theme.name === 'pl'
          ? `
        height: 35px;
        width: 35px;
        border: 1px solid ${props.theme.button.secondaryBackgroundColor};
        background-color: ${props.theme.button.secondaryBackgroundColor};

        svg {
          margin-top: 6px;
          height: 22px;
          
        }
        `
          : `
        height: 25px;
        width: 25px;
        border: 2px solid ${props.theme.navigation.fontColor};  

        svg {
          margin-top: 5px;
          height: 15px;
        }
        `}
    }
  }
`;

export const StyledPlFooterHeading = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 35px 0;

  div {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    width: 100%;
    max-width: 1200px;

    img {
      height: 80px;
      display: none;

      @media ${device.tablet} {
        display: block;
      }
    }
  }
`;
