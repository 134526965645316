import moment from "moment";
import React, { useState } from 'react';
import ButtonLink from "../button-link";
import Button from '../button';
import SessionToggle from '../session-toggle';
import { StyledSession } from './styles';
import { SessionProps } from './interface';
import { Class } from "../../pages/interface";

const Session: React.FC<SessionProps> = ({ 
  session,
  isShowMoreDropdownContentAvailable
 }) => {
  const [toggleStatus, setToggleStatus] = useState(false);

  const toggle = () => {
    setToggleStatus(!toggleStatus);
  };

  const getFormattedTime = (dateString: string, addingHour: boolean = false): string => {
    return moment(dateString).add(addingHour ? 1 : 0, "hours").format("hh:mma");
  }

  const isClassInformationAvailable = (sessionDescription: string): boolean => {
    return sessionDescription !== ""
  } 

  return (
    <StyledSession>
      <div className="session-header">
        <p>{getFormattedTime(session.CourseStartDateTime)} - {getFormattedTime(session.CourseStartDateTime, true)}</p>
        <div className="session-header__name-and-book">
          <h3>{session.Description.toUpperCase()}</h3>
          <ButtonLink size="large" id={""} label={"BOOK"} href="https://profile.everyoneactive.com/login" />
        </div>

        {isShowMoreDropdownContentAvailable && isClassInformationAvailable(session.CourseDescription) &&
          <button className="toggle-class-info-button" onClick={() => toggle()}>{toggleStatus ? 'Hide class info' : 'Show class info'}</button>
        }
      </div>
      {isShowMoreDropdownContentAvailable && isClassInformationAvailable(session.CourseDescription) &&
        <div className={`session__info session-toggle-wrapper${toggleStatus ? 'open' : ''}`}>
          <SessionToggle session={session}/>
        </div>
      }
    </StyledSession>
  );
};

export default Session;
