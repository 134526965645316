import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledMobileToggle = styled.div`
  display: flex;
  position: relative;
  ${(props) => props.theme.name === 'ea' && `text-transform: uppercase;`}
  padding-right: 40px;
  cursor: pointer;

  ${(props) =>
    props.theme.name === 'pl' &&
    `@media ${device.plNavBreak} {
      display: none;
    }`}

  ${(props) =>
    props.theme.name === 'ea' &&
    `@media ${device.eaNavBreak} {
      display: none;
    }`}

  span {
    font-size: 18px;
  }

  div {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    right: 0;
    top: -2px;
    width: 30px;
    height: 30px;

    div {
      transition: all 0.4s ease-in-out;
      align-self: center;
      display: flex;
      position: initial;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 1px solid
        ${(props) => props.theme.button.secondaryBackgroundColor};
      background-color: ${(props) =>
        props.theme.button.secondaryBackgroundColor};
      transform: translateY(-1px);
    }

    &.open {
      div {
        height: 1px;
        width: 100%;
        border-radius: 0;

        &:nth-child(1) {
          transform: rotate(45deg) translate(10px, 9px);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(10px, -10px);
        }
      }
    }
  }
`;

export const StyledNavigation = styled.nav`
  display: flex;

  ${(props) =>
    props.theme.name === 'pl' &&
    `
    max-width: 1200px;
    width: 100%;
    `}

  ul {
    display: flex;
    flex-direction: column;
    align-items: normal;
    overflow: hidden;
    position: fixed;
    padding: 0;
    margin: 0;
    list-style: none;
    max-width: 0;
    width: 100%;    
    height: 100vh;
    right: 0;
    top: 89px;
    z-index: 1001;
    background-color: ${(props) => props.theme.navigation.backgroundColor};
    color: ${(props) => props.theme.navigation.fontColor};

    ${(props) =>
      props.theme.name === 'pl' &&
      `
      justify-content: flex-start;
      `}

    &.open {
      background-color: ${(props) =>
        props.theme.name === 'ea'
          ? props.theme.outlineColor
          : props.theme.navigation.backgroundColor};
      max-width: 50vw;
      transition: max-width 0.4s ease-in-out;

      ${(props) =>
        props.theme.name === 'pl' &&
        `@media ${device.plNavBreak} {
          height: auto;
        }`}
    
      ${(props) =>
        props.theme.name === 'ea' &&
        `@media ${device.eaNavBreak} {
          height: auto;
        }`}

    }

    ${(props) =>
      props.theme.name === 'pl' &&
      `@media ${device.plNavBreak} {
        height: auto;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        max-width: 100%;
        width: 100%;
        max-width: 1200px;
        background-color: transparent;
        left: auto;
        top: auto;
        overflow: initial;
      }`}
  
    ${(props) =>
      props.theme.name === 'ea' &&
      `@media ${device.eaNavBreak} {
        height: auto;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        max-width: 100%;
        width: 100%;
        background-color: transparent;
        left: auto;
        top: auto;
        overflow: initial;
      }`}

    li {
      display: flex;
      position: relative;
      padding: 12px 12px;
      justify-content: space-between;
      align-items: center;
      ${(props) =>
        props.theme.name === 'ea' &&
        `border-bottom: 1px solid ${props.theme.primaryBackgroundColor};`}
     
        ${(props) =>
          props.theme.name === 'pl'
            ? `@media ${device.plNavBreak} {
            justify-content: center;
            padding: 5px 10px 0px 10px;
          }`
            : `@media ${device.eaNavBreak} {
            justify-content: center;
            padding: 5px 10px 0px 10px;
          }`}

      ${(props) =>
        props.theme.name === 'ea' &&
        `@media ${device.eaNavShrink} {
          padding: 5px 16px 0px 16px;
        }`}

      a {
        text-decoration: none;
        white-space: nowrap;
        color: ${(props) => props.theme.navigation.fontColor};
        font-size: 22px;
        opacity: 0.8;

        ${(props) =>
          props.theme.name === 'pl'
            ? `@media ${device.plNavBreak} {
              opacity: 1;
              font-size: 13px;
          }`
            : `@media ${device.eaNavBreak} {
              opacity: 1;
              font-size: 13px;
              text-transform: uppercase;
          }`}

        ${(props) =>
          props.theme.name === 'ea' &&
          `@media ${device.eaNavShrink} {
            font-size: 15px;
          }`}

        &::after {
          display: block;
          content: " ";
          opacity: 0;
          left: 0;
          top: 3px;
          position: relative;
          transition: all 0.4s ease-in-out;
          width: 0;
          max-width: 0;
          padding: 0;
          margin: 0;
          height: 2px;
          background-color: ${(props) => props.theme.navigation.fontHoverColor};
        }
  
        &:hover {
          color: ${(props) => props.theme.navigation.fontHoverColor};
          &::after {
            opacity: 1;
            width: 100%;
            max-width: 100%;
          }
        }
      }

      ${(props) =>
        props.theme.name === 'pl'
          ? `@media ${device.maxplNavBreak} {
            &::before {
              order: 2;
              content: " ";
              width: 6px;
              height: 6px;
              border-right: 3px solid ${props.theme.navigation.fontColor};
              border-top: 3px solid ${props.theme.navigation.fontColor};
              opacity: 0.8; 
              transform: rotate(45deg);
            }
        }`
          : `@media ${device.maxeaNavBreak} {
            &::before {
              order: 2;
              content: " ";
              width: 6px;
              height: 6px;
              border-right: 3px solid ${props.theme.navigation.fontColor};
              border-top: 3px solid ${props.theme.navigation.fontColor};
              opacity: 0.8; 
              transform: rotate(45deg);
            }
        }`}
      
      ${(props) =>
        props.theme.name === 'ea' &&
        `
      @media ${device.eaNavBreak} {
        &:last-child {
          background: ${props.theme.button.primaryBackgroundColor};
          line-height: 35px;
          transition: all 0.4s ease-in-out;

          &:hover {
            background-color: ${props.theme.button.secondaryBackgroundColor};

            &::after {
              opacity: 0;
            }
          }

          a {
            color: ${props.theme.primaryBackgroundColor};
          }
        }
      }
      `}
    }
  }
`;
