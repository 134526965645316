import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ContentItems } from '../../components/body-info/interface';
import Button from '../button';
import { StyledCentreSelector } from './styles';
import { CentreSelectorProps } from "./interface";
import { Centre } from "../../pages/interface";

const CentreSelector: React.FC<CentreSelectorProps> = ({
  currentlySelectedCentre,
  availableCentres,
  onViewClassesButtonClick,
}) => {
  const {
    contentItems,
  }: {
    contentItems: ContentItems;
  } = useContext(ThemeContext);

  const [
    newlySelectedCentreDisplayName,
    setNewlySelectedCentreDisplayName,
  ] = useState<string>("");
  const [newlySelectedCentre, setNewlySelectedCentre] = useState<Centre>();

  useEffect(() => {
    if (currentlySelectedCentre !== null && currentlySelectedCentre !== undefined) {
      setNewlySelectedCentreDisplayName(currentlySelectedCentre.name);
    }
  }, [currentlySelectedCentre]);

  useEffect(() => {
    /*-- get the newly selected centre here --*/
    if (availableCentres !== null && availableCentres !== undefined && availableCentres.length > 0) {
      if (
        availableCentres.find(
          (availableCentre) =>
            availableCentre.name === newlySelectedCentreDisplayName
        ) !== undefined
      ) {
        const newlySelectedCentre: Centre = availableCentres.find(
          (availableCentre) =>
            availableCentre.name === newlySelectedCentreDisplayName
        )!;
        setNewlySelectedCentre(newlySelectedCentre);
      }
    }
  }, [newlySelectedCentreDisplayName]);

  const handleButtonClick = (): void => {
    if (newlySelectedCentre !== undefined) {
      onViewClassesButtonClick(newlySelectedCentre);
    }
  };

  return (
    <StyledCentreSelector>
      <h2 style={{ margin: "70px 0 20px 0" }} className="ea">
        {contentItems.header}
      </h2>
      <div>
        <input
          type="search"
          list="availableCentres"
          placeholder="Please select a centre"
          value={newlySelectedCentreDisplayName}
          onChange={(event) =>
            setNewlySelectedCentreDisplayName(event?.target.value)
          }
        />
        <datalist id="availableCentres">
          {availableCentres &&
            availableCentres.map((availableCentre) => (
              <option
                key={availableCentre.site_id}
                value={availableCentre.name}
              />
            ))}
        </datalist>
        <div>
          <span>
            <Button
              size="large"
              label="View Classes"
              onClick={() => handleButtonClick()}
            />
          </span>
        </div>
      </div>
    </StyledCentreSelector>
  );
};

export default CentreSelector;
