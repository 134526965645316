import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { FooterProps, FooterItem } from './interface';
import { StyledFooter, StyledPlFooterHeading } from './styles';
import Button from '../button';
import Facebook from '../icons/facebook';
import Twitter from '../icons/twitter';
import Youtube from '../icons/youtube';
import googlePlayImage from '../../resources/images/google-play.png';
import appStoreImage from '../../resources/images/app-store.png';
import verifiedImage from '../../resources/images/verified.png';

const Footer: React.FC<FooterProps> = () => {
  const [selected, setSelected] = useState('');
  const { footer, logo, name } = useContext(ThemeContext);
  const columns = [footer.items.col1, footer.items.col2, footer.items.col3];

  return (
    <StyledFooter>
      {name === 'pl' && (
        <StyledPlFooterHeading>
          <div>
            <img src={logo} alt="" />
            <span>
              <Button size="large" label="Join Now" />
            </span>
          </div>
        </StyledPlFooterHeading>
      )}
      {name === 'ea' ? (
        <div>
          <div>
            <h2
              className={selected !== 'c-1' ? '' : 'open'}
              onClick={() => setSelected(selected === 'c-1' ? '' : 'c-1')}
            >
              {footer.items.col1.title}
            </h2>

            <div className={`toggle${selected !== 'c-1' ? ' hide' : ''}`}>
              <ul>
                {footer.items.col1.items.map(
                  (item: FooterItem, idx: number) => (
                    <li key={idx}>
                      {item.href ? (
                        <a href={item.href}>{item.title}</a>
                      ) : (
                        item.title
                      )}
                    </li>
                  )
                )}
              </ul>
              <ul>
                {footer.items.col2.items.map(
                  (item: FooterItem, idx: number) => (
                    <li key={idx}>
                      {item.href ? (
                        <a href={item.href}>{item.title}</a>
                      ) : (
                        item.title
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div>
            <div>
              <div>
                <h2
                  className={selected !== 'c-2' ? '' : 'open'}
                  onClick={() => setSelected(selected === 'c-2' ? '' : 'c-2')}
                >
                  {footer.items.col2.title}
                </h2>

                <ul className={`toggle${selected !== 'c-2' ? ' hide' : ''}`}>
                  {footer.items.col3.items.map(
                    (item: FooterItem, idx: number) => (
                      <li key={idx}>
                        <a href={item.href}>{item.title}</a>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div>
                <div>
                  <h2
                    className={selected !== 'c-3' ? '' : 'open'}
                    onClick={() => setSelected(selected === 'c-3' ? '' : 'c-3')}
                  >
                    {footer.items.col3.title}
                  </h2>

                  <div className={`toggle${selected !== 'c-3' ? ' hide' : ''}`}>
                    <a href="https://itunes.apple.com/gb/app/everyone-active/id974698722?mt=8">
                      <img src={googlePlayImage} alt="" />
                    </a>

                    <a href="https://play.google.com/store/apps/details?id=com.innovatise.everyoneactive&hl=en_GB">
                      <img src={appStoreImage} alt="" />
                    </a>

                    <span className="social-icons">
                      <a href="https://facebook.com/everyoneactive">
                        <Facebook />
                      </a>
                      <a href="https://twitter.com/everyoneactive">
                        <Twitter />
                      </a>
                      <a href="https://instagram.com/everyoneactive">
                        <Youtube />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="ul-wrapper">
            {columns.map((column, idx: number) => (
              <ul key={idx}>
                {column.items.map((item: FooterItem, idx: number) => (
                  <li key={idx}>
                    {item.href ? (
                      <a href={item.href}>{item.title}</a>
                    ) : (
                      item.title
                    )}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      )}

      <div className="sub-footer">
        <div>
          <span>
            <span className="copy">{footer.items.copyright}</span>
          </span>
          <span>
            {name === 'ea' ? (
              <img src={verifiedImage} alt="" />
            ) : (
              <span className="social-icons">
                <a href="https://facebook.com/everyoneactive">
                  <Facebook />
                </a>
                <a href="https://twitter.com/everyoneactive">
                  <Twitter />
                </a>
                <a href="https://instagram.com/everyoneactive">
                  <Youtube />
                </a>
              </span>
            )}
          </span>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
