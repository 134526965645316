import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledCentreSelector = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    max-width: 470px;
    align-self: center;
    padding-bottom: 60px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media ${device.tablet} {
      flex-direction: row;
    }

    div {
      display: flex;
      flex-direction: row;
      min-width: 200px;

      span {
        padding: 6px 0;
      }
    }
  }
`;
