import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledDatePicker = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;

  .react-datepicker-wrapper {
    width: 100%;

    @media ${device.tablet} {
      width: 200px;
    }

    input[type='text'] {
      font-family: 'Barlow Semi Condensed', sans-serif;
      font-size: 20px;
      line-height: 24px;
      background-image: url(${(props) => props.theme.datePicker.backgroundUrl});
      background-repeat: no-repeat;
      background-position: right 5px center;
      margin: 0;
      border-radius: 4px;

      @media ${device.tablet} {
        width: 200px;
      }
    }
  }
`;
