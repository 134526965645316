import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import HomePageVerticalTimetableVariant from "./home-variants/timetable/vertical/home-vertical-timetable";
import mockCentresData from "../mock/centres.json";


const HomePage = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomePageVerticalTimetableVariant/>
        </Route>

        <Route exact path="/spelthorne">
          <Redirect to="/spelthorne/a"/>
        </Route>

        <Route exact path="/spelthorne/a">
          <HomePageVerticalTimetableVariant initialSelectedCentre={mockCentresData[0]}/>
        </Route>

        <Route exact path="/spelthorne/b">
          <HomePageVerticalTimetableVariant initialSelectedCentre={mockCentresData[0]} isShowMoreDropdownContentAvailable={true}/>
        </Route>

        <Route exact path="/horfield">
          <Redirect to="/horfield/a"/>
        </Route>

        <Route exact path="/horfield/a">
          <HomePageVerticalTimetableVariant initialSelectedCentre={mockCentresData[1]}/>
        </Route>

        <Route exact path="/horfield/b">
          <HomePageVerticalTimetableVariant initialSelectedCentre={mockCentresData[1]} isShowMoreDropdownContentAvailable={true}/>
        </Route>
      </Switch>
    </Router>
  );
};

export default HomePage;
