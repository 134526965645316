import React from "react";
import { StyledButtonLink } from "./styles";
import { ButtonLinkProps } from "./interface";

const ButtonLink: React.FC<ButtonLinkProps> = ({
    primary = false,
    size = "medium",
    backgroundColor,
    id,
    label,
    href
}) => {
    const mode = primary ? "button--primary" : "button--secondary";

    return (
        <StyledButtonLink type="button" id={id} className={["button", `button--${size}`, mode].join(" ")}
        style={{ backgroundColor }} href={href}>{label}</StyledButtonLink>
    );
};

export default ButtonLink;