import styled from "styled-components";
import { device } from "../../themes/breakpoints";
import loader from "../../resources/icons/loader.svg";

export const StyledHorizontalFilterBar = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
  grid-gap: 16px;
  padding: 0 20px 36px;
  position: relative;

  @media ${device.laptop} {
    padding: 0 0 36px;
  }
`;

export const LoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  &:before {
    background: transparent url(${loader}) no-repeat center center;
    content: "";
    display: block;
    margin: 48px auto;
    text-align: center;
    height: 48px;
    width: 48px;
  }
`;
