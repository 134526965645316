import React from 'react';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { StyledHeaderInfoBox, StyledWrapper } from './styles';
import { HeaderInfoProps, HeaderItems } from './interface';
import InfoBox from '../info-box';

const HeaderInfo: React.FC<HeaderInfoProps> = () => {
  const {
    headerItems,
  }: {
    headerItems: HeaderItems;
  } = useContext(ThemeContext);

  return (
    <StyledHeaderInfoBox>
      <StyledWrapper>
        {headerItems.items.map((item, idx) => (
          <InfoBox key={idx} image={item.icon}>
            <b>{item.title}</b>
            <br />
            {item.description}
          </InfoBox>
        ))}
      </StyledWrapper>
    </StyledHeaderInfoBox>
  );
};

export default HeaderInfo;
