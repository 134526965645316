import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledSession = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 12px 0;
  padding: 20px 15px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  border: 1px solid ${(props) => props.theme.outlineColor};
  border-radius: 4px;
  box-shadow: 2px 4px 5px ${(props) => props.theme.outlineColor};

  .session-header {
    * {
      margin: 0;
    }

    p {
      font-family: "Circular Std Book", sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #333;
    }

    &__name-and-book {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-size: 20px;
      }
    }

    .toggle-class-info-button {
      border: 0 none;
      background: transparent;
      color: #3D96CA;
      padding: 0;
      text-decoration: underline;
    }
  }

  .info-block {
    display flex;
    jusify-content: space-between;
    margin-right: 0;
  }

  .session-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${device.tablet} {
      display: grid;
      grid-template-columns: 5fr 12fr;
    }

    @media ${device.laptop} {
      display: grid;
      grid-template-columns: 5fr 7fr;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media ${device.tablet} {
        flex-direction: row;
      }

      div {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        &.session-block {
          @media ${device.tablet} {
            flex-direction: row;
            justify-content: center;
          }
        }

        @media ${device.tablet} {
          line-height: 20px;
          font-size: 16px;
        }
      }

      img {
        margin: 0 10px 0 0;
      }
    }
  }

  h3 {
    align-self: center;
    margin: 0;
  }

  span {
    &.class-toggle {
      color: ${(props) => props.theme.highlightColor};
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid ${(props) => props.theme.highlightColor};
      margin: 0 20px 0 0;
      font-size: 13px;

      &::after {
        content: ' ';
        transition: all 0s;
        position: absolute;
        right: -13px;
        top: 3px;
        width: 6px;
        height: 6px;
        border-bottom: 1px solid;
        border-right: 1px solid;
        transform: rotate(45deg) translate(0px, 0px);
      }

      &.open {
        &::after {
          transform: rotate(-135deg) translate(-6px, -6px);        
          top: 0px;
        }
      }
    }
  }

  .session-toggle-wrapper {
    display: flex;
    overflow: hidden;
    transition: all 2s ease-in-out;
    max-height: 0;
    opacity: 00;

    ul {
      padding: 0;
      margin: 8px 0;
    }

    &.open {
      max-height: 800px;
      opacity: 1;
    }
  }
`;
