import styled from 'styled-components';

export const StyledButtonLink = styled.a`
  cursor: pointer;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-weight: 800;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;
  
  &.button--primary {
    color: ${(props) => props.theme.button.primaryFontColor};
    background-color: ${(props) => props.theme.button.primaryBackgroundColor};
    border: 1px solid ${(props) => props.theme.button.primaryFontColor};
  }

  &.button--secondary {
    transition: all 0.4s ease-in-out;
    border: 1px solid ${(props) => props.theme.button.secondaryBackgroundColor};
    color: ${(props) => props.theme.button.secondaryFontColor};
    background-color: ${(props) => props.theme.button.secondaryBackgroundColor};
    &:hover {
      background-color: ${(props) => props.theme.button.secondaryFontColor};
      color: ${(props) => props.theme.button.secondaryBackgroundColor};
      border: 1px solid ${(props) =>
        props.theme.button.secondaryBackgroundColor};
    }
  }

  &.button--small {
    font-size: 12px;
    padding: 10px 16px;
  }

  &.button--medium {
    font-size: 16px;
    padding: 12px 32px;
  }

  &.button--large {
    font-size: 18px;
    padding: 12px 32px;
  }
}`;