import React, { useState } from "react";
import { StyledActivityNameFilter } from "./styles";
import { ActivityNameFilterProps } from "./interface";
import { FilterItem } from "../../../pages/interface";

const ActivityNameFilter: React.FC<ActivityNameFilterProps> = ({ 
    activityNameFilterSection, 
    activityFilterWasPressed 
}) => {

    const [isCustomSelectVisible, setIsCustomSelectVisible] = useState<Boolean>(false);

    const getSelectedActivityListString = (activityListItems: FilterItem[]): string => {
        const selectedActivityListItems: FilterItem[] = activityListItems.filter((activityListItem) => activityListItem.isSelected);
        if (activityListItems.length === selectedActivityListItems.length) {
            return "All activities selected";
        } else if (selectedActivityListItems.length === 0) {
            return "Please select an option";
        } else {
            const selectedActivityListItemNames: string[] = selectedActivityListItems.map((selectedActivityListItem) => selectedActivityListItem.filterName);
            const selectedActivityListItemNamesString: string = selectedActivityListItemNames.join(", ");
            return selectedActivityListItemNamesString;
        }
    }

    const dismissCustomSelect = () => {
        setIsCustomSelectVisible(false);
    }

    return (
        <StyledActivityNameFilter tabIndex={0} onBlur={() => dismissCustomSelect()}>
            <p className="filter-label">{ activityNameFilterSection.sectionName }</p>
            <div className="filter-input" onClick={() => {
                if (isCustomSelectVisible) {
                    dismissCustomSelect();
                } else {
                    setIsCustomSelectVisible(true);
                }
            }}>
                <span>{getSelectedActivityListString(activityNameFilterSection.sectionFilters)}</span>
                <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.79272 4.37158L8.24255 0.0786818L8.94966 0.785788L4.49983 5.07869L3.79272 4.37158Z" fill="#333333"/>
                    <path d="M0.707153 0.0791016L5.09979 4.47174L4.39269 5.17885L4.66704e-05 0.786208L0.707153 0.0791016Z" fill="#333333"/>
                </svg>
            </div>
            {isCustomSelectVisible &&
                <ul className="filter-list">
                    {activityNameFilterSection.sectionFilters.map((sectionFilter, index) =>
                        <li key={sectionFilter.filterName + '-' + index} className="filter-list__item" tabIndex={0} onMouseDown={() => {
                            activityFilterWasPressed(sectionFilter.filterName);
                        }}>
                            <p>{sectionFilter.filterName}</p>
                            {sectionFilter.isSelected &&
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11" cy="11" r="10" fill="#FF080D"/>
                                    <path d="M11 0C4.92486 0 0 4.92486 0 11C0 17.0751 4.92486 22 11 22C17.0751 22 22 17.0751 22 11C21.9935 4.92758 17.0724 0.00649135 11 0ZM11 20.4285C5.79272 20.4285 1.57141 16.2072 1.57141 11C1.57141 5.79272 5.79272 1.57141 11 1.57141C16.2072 1.57141 20.4285 5.79272 20.4285 11C20.4229 16.2049 16.2049 20.4229 11 20.4285Z" fill="#FF080D"/>
                                    <path d="M17.0361 6.51592C16.7316 6.22183 16.2489 6.22183 15.9445 6.51592L8.64282 13.8175L6.05544 11.2302C5.75398 10.918 5.25654 10.9094 4.94445 11.2109C4.63231 11.5123 4.62371 12.0098 4.92516 12.3218C4.93147 12.3284 4.93791 12.3348 4.94445 12.3411L8.08732 15.484C8.39412 15.7908 8.89151 15.7908 9.19831 15.484L17.0555 7.62686C17.3569 7.31477 17.3483 6.81737 17.0361 6.51592Z" fill="white"/>
                                </svg>
                            }
                        </li>
                    )}
                </ul>
            }
        </StyledActivityNameFilter>
    );
};

export default ActivityNameFilter;