import React from 'react';
import Navigation from '../navigation';
import { NavigationItems } from '../navigation/interface';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { HeaderProps } from './interface';
import { StyledHeader, StyledWrapper, StyledSvg } from './styles';

const Header: React.FC<HeaderProps> = () => {
  const {
    navigationItems,
    logo,
  }: {
    navigationItems: Array<NavigationItems>;
    logo: string;
  } = useContext(ThemeContext);

  return (
    <StyledHeader>
      <StyledWrapper>
        <StyledSvg>
          <img src={logo} alt="Logo" />
        </StyledSvg>
        <div className="nav-wrapper">
          <Navigation items={navigationItems} />
        </div>
      </StyledWrapper>
    </StyledHeader>
  );
};

export default Header;
