import React from 'react';
import { StyledInfoBox } from './styles';
import { InfoBoxProps } from './interface';

const InfoBox: React.FC<InfoBoxProps> = ({ image, children }) => {
  return (
    <StyledInfoBox>
      <div>
        <img src={image} alt="" />
      </div>
      <div style={{ display: 'block' }}>{children}</div>
    </StyledInfoBox>
  );
};

export default InfoBox;
