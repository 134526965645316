export const getClassIntensityLevel = (className: string): string => {
  const lowIntensityClasses: string[] = [
    "Body Balance",
    "Evergreens",
    "Pilates",
    "Yoga",
    "Lbt",
    "Piyo",
    "Zumba",
    "Zumba Gold",
    "Latin Dance",
    "Virtual Body Balance",
    "Virtual CX",
    "Virtual SHBAM",
  ];
  const highIntensityClasses: string[] = [
    "Body Combat",
    "Body Pump",
    "Body Attack",
    "Boot Camp",
  ];
  switch (true) {
    case isPartialMatch(className, lowIntensityClasses):
      return "Low";
    case isPartialMatch(className, highIntensityClasses):
      return "High";
    default:
      return "Medium";
  }
};

export const getClassDescription = (className: string): string => {
  switch (true) {
    case isPartialMatch(className, "Body Contact"):
      return "Body Combat is the empowering cardio workout where you are totally unleashed. This fiercely energetic program is inspired by Martial Arts and draws from a wide array of disciplines such as Karate, Boxing, Tae Kwon Do, Tai Chi and Muay Thai. Supported by driving music and powerful role model instructors, Strike, Punch, Kick and Kata your way through calories to superior cardio fitness.";
    case isPartialMatch(className, "Body Pump"):
      return "Body Pump is the original barbell class that strengthens your entire body. This 60-minute workout challenges all your major muscle groups by using the best weight-room exercises like Squats, Presses, Lifts and Curls. Great music, awesome instructors and your choice of weights inspire you to get the results you came for - and fast!";
    case isPartialMatch(className, "Evergreens"):
      return "A fun, low intensity, low impact session for older adults who want to work their heart, muscles and increase flexibility, all in one easy to follow class. These sessions vary centre to centre and may include badminton and other activities.";
    case isPartialMatch(className, "Pilates"):
      return "These sessions are designed to concentrate on slow and measured movements in order to create lean and stronger muscles around your torso. A gentle but highly effective session for improving posture, flexibility and preventing injury.";
    case isPartialMatch(className, "Virtual RPM"):
      return "This virtual cycling class allows you to choose how hard you want to push yourself, while still keeping up with the instructor who takes you and the rest of the class through hill climbs, sprints and flat rides. Virtual RPM is fantastic for improving cardio fitness, as well as burning fat and calories, while the low-impact nature of the exercise will also help you avoid injury. Virtual classes are great if you just want to give the class a trial run. They're played on big screens with music and are led by instructors who have actually developed the classes.";
    case isPartialMatch(className, "Yoga"):
      return "One of the worlds most practiced spiritual disciplines. Developed over thousands of years these sessions concentrate on bringing balance and harmony to both body and mind, through postures and breath. They can help to boost the immune system, encourage relaxation and correct posture alignment";
    case isPartialMatch(className, "Body Attack"):
      return "Body Attack is the sports-inspired cardio workout for building strength and stamina. This high-energy interval training class combinies athletic aerobic movements with strength and stabilization exercises. Dynamic instructors and powerful music motivate everyone towards their fitness goals – from the weekend athlete to the hardcore competitor!";
    case isPartialMatch(className, "Group Cycling"):
      return "This high energy class can help you burn up to 450 calories per 30-min without the hassle of learning any complicated moves. This indoor group cycling session is set to motivational music and led by inspirational instructors.";
    case isPartialMatch(className, "Lbt"):
      return "Also known as Legs Bums and Tums, get to grips with those wobbly bits! A complete lower body workout for all those problem areas, incorporating the use of hand weights, steps, bars and bands.";
    case isPartialMatch(className, "Piyo"):
      return "Stretch, and strengthen—all in one workout! This unique total-body conditioning workout inspired by the muscle-sculpting, core-firming benefits of Pilates and the strength and flexibility training of flowing yoga movements.";
    case isPartialMatch(className, "Zumba"):
      return "Zumba is a spicy hot, hip swivelling workout where African, Caribbean and Latin dance moves are combined with aerobic movements to create a fun and effective work out.";
    case isPartialMatch(className, "Body Balance"):
      return "Body Balance is the Yoga, Tai Chi, Pilates workout that builds flexibility and strength and leaves you feeling centred and calm. Controlled breathing, concentration and a carefully structured series of stretches, moves and poses to music create a holistic workout that brings the body into a state of harmony and balance.";
    case isPartialMatch(className, "Body Step"):
      return "Body Step is the energizing step workout that makes you feel liberated and alive. Using a height-adjustable step and simple movements on, over and around it you get huge motivation from sing-along music and approachable instructors. Cardio blocks push fat-burning systems into high gear followed by muscle conditioning tracks that shape and tone your body.";
    case isPartialMatch(className, "Zumba Gold"):
      return "Takes the Zumba® formula and modifies the moves and pacing to suit the needs of the active older participant, as well as those just starting their journey to fit and healthy lifestyle.";
    case isPartialMatch(className, "Total Toning"):
      return "Exactly what it says on the tin! A session that tones up and strengthens all those key areas like your bum, legs and arms whilst challenging your heart and lungs with some fun calorie burning exercises.";
    default:
      return "";
  }
};

export const getClassDoNotForgetText = (className: string): string => {
  switch (true) {
    case isPartialMatch(className, "Body Contact"):
      return "Bring your water bottle and comfortable sports clothes.";
    case isPartialMatch(className, "Body Pump"):
      return "Bring your water bottle";
    case isPartialMatch(className, "Evergreens"):
      return "Bring some water and a smile.";
    case isPartialMatch(className, "Pilates"):
      return "Bring comfortable clothing, a mat and your water bottle";
    case isPartialMatch(className, "Virtual RPM"):
      return "Bring your water bottle and a towel";
    case isPartialMatch(className, "Yoga"):
      return "Bring your exercise mat and your water bottle";
    case isPartialMatch(className, "Body Attack"):
      return "Don’t forget to bring your waterbottle!";
    case isPartialMatch(className, "Group Cycling"):
      return "Don’t forget your waterbottle!";
    case isPartialMatch(className, "Lbt"):
      return "Don’t forget to bring your water bottle and comfy clothes.";
    case isPartialMatch(className, "Piyo"):
      return "Don’t forget to bring your water and a mat.";
    case isPartialMatch(className, "Zumba"):
      return "Don’t forget your water bottle and smile!";
    case isPartialMatch(className, "Body Balance"):
      return "Don’t forget your water bottle and a mat.";
    case isPartialMatch(className, "Body Step"):
      return "Don’t forget your waterbottle.";
    case isPartialMatch(className, "Zumba Gold"):
      return "Don’t forget to bring your water bottle and a smile!";
    case isPartialMatch(className, "Total Toning"):
      return "Don’t forget to bring your water bottle";
    default:
      return "";
  }
};

export const getClassKeyBenefits = (className: string): string[] => {
  switch (true) {
    case isPartialMatch(className, "Body Contact"):
      return [
        "Improves Cardiovascular health",
        "Range of powerful activities to keep it interesting",
        "Helps mental and physical wellbeing",
      ];
    case isPartialMatch(className, "Body Pump"):
      return [
        "Builds muscular strength",
        "Adaptable for your own ability",
        "Improves mental and physical wellbeing",
      ];
    case isPartialMatch(className, "Evergreens"):
      return [
        "A range of activities to be involved in",
        "Work all parts of the body with low impact",
        "Meet new people while improving our physical wellbeing",
      ];
    case isPartialMatch(className, "Pilates"):
      return [
        "Builds strength and flexibility",
        "Improves mental and physical wellbeing",
        "Reduces the chance of injury",
      ];
    case isPartialMatch(className, "Virtual RPM"):
      return [
        "Improves cardiovascular fitness",
        "Suitable for all abilities",
        "Fun, fast and exciting class to suit you",
      ];
    case isPartialMatch(className, "Yoga"):
      return [
        "Supports your mental and physical wellbeing",
        "Strengthens muscles",
        "Boosts immune system",
      ];
    case isPartialMatch(className, "Body Attack"):
      return [
        "Builds strength and stamina",
        "Improves cardiovascular fitness",
        "Range of exercises",
      ];
    case isPartialMatch(className, "Group Cycling"):
      return [
        "Quick and easy way to burn calories",
        "Fun and exciting session",
        "Improves physical and mental wellbeing",
      ];
    case isPartialMatch(className, "Lbt"):
      return [
        "Supports physical and mental wellbeing",
        "Specific work out just for your lower body",
      ];
    case isPartialMatch(className, "Piyo"):
      return [
        "Develops your core, strength and flexibility",
        "Total body conditioning",
        "Supports physical and mental well being",
      ];
    case isPartialMatch(className, "Zumba"):
      return [
        "Fast and fun dance class",
        "Creative aerobic workout",
        "Meet new people who like a dance",
      ];
    case isPartialMatch(className, "Body Balance"):
      return [
        "Builds strength while improving your flexibility",
        "Supports your physical and mental wellbeing",
        "Holistic workout",
      ];
    case isPartialMatch(className, "Body Step"):
      return [
        "Adaptable for you",
        "Builds Cardiovascular fitness",
        "Tones your body",
      ];
    case isPartialMatch(className, "Zumba Gold"):
      return [
        "Benefits of Zumba while suitable for the older participant",
        "Supports a healthy lifestyle",
        "Improves physical and mental wellbeing",
      ];
    case isPartialMatch(className, "Total Toning"):
      return [
        "A Full body workout",
        "Improves Cardiovascular fitness",
        "Support physical and mental wellbeing",
      ];
    default:
      return [];
  }
};

const isPartialMatch = (
  className: string,
  namesToMatchAgainst: string | string[]
): boolean => {
  const formattedClassName: string = formatString(className);
  if (typeof namesToMatchAgainst === "string") {
    const formattedNameToMatchAgainst: string = formatString(
      namesToMatchAgainst
    );
    return formattedClassName.includes(formattedNameToMatchAgainst);
  } else {
    const formattedNamesToMatchAgainst: string[] = namesToMatchAgainst.map(
      (nameToMatchAgainst: string) => formatString(nameToMatchAgainst)
    );
    return (
      formattedNamesToMatchAgainst.filter((formattedNameToMatchAgainst) =>
        formattedClassName.includes(formattedNameToMatchAgainst)
      ).length > 0
    );
  }
};

const formatString = (stringToFormat: string): string => {
  return stringToFormat.trim().replace(" ", "").toLowerCase();
};
