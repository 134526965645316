import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledHeaderInfoBox = styled.div`
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  padding: 0 20px;

  ${(props) => (props.theme.name === 'ea' ? `top: 89px;` : 'top: 110px;')}
  justify-content: center;
  background-color: ${(props) => props.theme.infoBox.backgroundColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  z-index: 999;

  ${(props) =>
    props.theme.name === 'pl' &&
    `@media ${device.plNavBreak} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }`}

  ${(props) =>
    props.theme.name === 'ea' &&
    `@media ${device.eaNavBreak} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }`}
`;

export const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
