import styled from 'styled-components';

export const StyledIntensity = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: flex-start;
  width: 30px;
  max-width: 30px;
  height: 22px;
  padding: 0 4px 2px 0;

  span {
    width: 3px;
    border: 1px solid ${(props) => props.theme.outlineColor};
    margin-top: auto;

    &.active {
      background: #63c54f;
    }

    &:nth-child(1) {
      height: 18%;
    }

    &:nth-child(2) {
      height: 36%;
    }

    &:nth-child(3) {
      height: 54%;
    }

    &:nth-child(4) {
      height: 72%;
    }

    &:nth-child(5) {
      height: 90%;
    }
  }
`;
