import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledBodyInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${device.laptop} {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
`;
