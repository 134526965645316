import styled from 'styled-components';
import { device } from '../../themes/breakpoints';

export const StyledSessionToggle = styled.div`
  
  .session-toggle {
    margin-top: 14px;
    border-top: 1px solid #EEEEEE;
    padding: 14px;

    &__top {
      display: flex;
      flex-direction: column;
      width: 100%;

      .session-top__left,
      .session-top__right {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .session-top__left div,
      .session-top__right div {

        h4,
        p {
          margin: 0;
        }

        h4 {
          font-size: 21px;
          padding-bottom: 12px;
        }

        p {
          font-family: "Circular Std Book", sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: #333;
        }
      }

      .session-top__left div:first-of-type p {
        padding-bottom: 12px;
      }

      .session-top__left .toggle-detail {
        margin: 0;
        padding-bottom: 24px;

        &__item {
          display: flex;
          align-items: center;

          p {
            padding-left: 12px;
          }
        }
      }

      .session-top__right div > ul {
        margin: 0;
        list-style: none;
        padding-left: 0;
        padding-bottom: 24px;

        li {
          display: flex;
          align-items: center;
          font-family: "Circular Std Book", sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: #333;
          padding-bottom: 10px;

          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }
    }

    &__bottom .highlight {
      background-color: #F6F9FA;
      padding: 12px;

      * {
        margin: 0;
      }

      > div {
        display: flex;
        align-items: center;
        padding-bottom: 12px;
        
        h5 {
          padding-left: 12px;
        }
      }

      p {
        font-family: "Circular Std Book", sans-serif;
        font-size: 15px;
        font-weight: 400;
        color: #333;
      }
    }
  }

  @media ${device.laptop} {
    .session-toggle {
      margin-top: 26px;
      padding: 34px 16px 0;

      &__top {
        flex-direction: row;

        .session-top__left {
          width: 60%;
          padding-right: 24px;
        }

        .session-top__right {
          width: 40%;
        }
      }
    }
  }
`;
